.timer__container {
	margin-top: 50px;
}

.timer__container h4 {
	text-align: center;
	font-size: 2.5rem;
	font-weight: 900;
	color: grey;
	margin-bottom: 80px;
}

.loading {
	text-align: center;
	background-color: black;
	color: white;
	width: 100%;
	padding: 5px 10px;
	margin-top: 10px;
	margin-right: 20px;
}

.loading p {
	font-size: 5.5rem;
	font-weight: 700;
	letter-spacing: 50px;
}

.wrapper {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#countdown .box {
	margin-top: 20px;
	text-align: center;
}

.box {
	margin-right: 10px;
	margin-left: 5px;
}

.box p {
	font-size: 3.5rem;
	font-weight: 700;
	color: grey;
}

.box .text {
	align-items: center;
	justify-content: center;
	color: rgba(187, 34, 39, 0.6);
	font-weight: 600;
	margin-top: 0;
}

@media (max-width: 991px) {
	.timer__container {
		margin-top: 20px;
	}

	.timer__container h4 {
		text-align: center;
		font-size: 2rem;
		font-weight: 900;
		color: grey;
		margin-bottom: 50px;
		margin-top: 50px;
	}

	.loading {
		text-align: center;
		background-color: black;
		color: white;
		margin-top: 70px;
		margin-right: 20px;
	}

	.loading p {
		font-size: 1.5rem;
		font-weight: 700;
		letter-spacing: 20px;
	}

	.wrapper {
		margin-top: 20px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.box {
		margin-right: 0px;
		margin-left: 0px;
	}

	.box p {
		font-size: 2rem;
		font-weight: 700;
		color: grey;
	}

	.box .text {
		color: red;
		font-weight: 400;
		font-size: 0.8rem;
	}

	.min {
		margin-top: 40px;
	}
}
