.footer__section {
	height: 6.3rem;
	width: 100%;
	background-color: rgb(0, 0, 0);
	color: #fff;
	z-index: 98;
	position: fixed;
	bottom: 0;
}

.footer__section p,
.footer__section h5 {
	margin: 0;
	padding: 0;
}

.footer__container {
	padding-left: 50px;
	padding-right: -50px;
	padding-top: 20px;
}

.footer__social {
	display: flex;
	/* justify-content: space-between; */
}

.footer__social .soc_text {
	color: grey;
	font-size: 1.2rem;
	padding-top: 0.3rem;
}

.footer__social--icons {
	margin-left: 40px;
}

.footer__icons {
	padding-right: 15px;
	padding-bottom: 5px;
	font-size: 2.5rem;
	color: grey;
}

.facebook__icon svg path:hover {
	color: #4267b2;
}

.twitter__icon svg path:hover {
	color: #1da1f2;
}

.instagram__icon svg path:hover {
	color: #e1306c;
}

.footer__download {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 50px;
}

.footer__download--playstore {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 10px;
	border: 1px solid rgb(212, 210, 210);
	border-radius: 5px;
	margin-right: 15px;
}

.footer__download--playstore span {
	font-size: 0.8rem;
	color: grey;
}

.footer__download--playstore .download__icon {
	margin-left: 10px;
}

.footer__download--appstore {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 10px;
	border: 1px solid rgb(212, 210, 210);
	border-radius: 5px;
}

.footer__download--appstore span {
	font-size: 0.8rem;
	color: grey;
}

.download__icons {
	font-size: 1.2rem;
	margin-left: 10px;
}

.footer_share {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10rem;
	cursor: pointer;
}

.footer_share h5 {
	margin-right: 10px;
	color: grey;
}

.share__icon {
	padding-bottom: 10px;
	font-size: 1.5rem;
	font-weight: 700;
	color: grey;
}

.contact_content {
	display: flex;
	align-items: center;
	margin-top: 10px;
	font-weight: bold;
}

.contact_content p {
	margin: 0 0 0 10px;
	padding: 0px;
}

.footer_agree {
	display: flex;
	align-items: center;
}

.footer_agree > div > a {
	padding-right: 20px;
}
.footer_agree a:hover {
	color: #fff !important;
}
.footer_agree > div > a:hover {
	color: "#ffffff" !important;
}

@media (max-width: 991px) {
	.footer__section {
		height: 31vh;
		width: 100%;
		background-color: rgb(20, 18, 18);
		color: #fff;
	}

	.footer__social {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.footer__social h5 {
		color: grey;
		font-size: 0.8rem;
	}

	.footer__social--icons {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.footer__download {
		margin-top: 5px;
		margin-left: 0;
	}

	.footer__download--playstore span {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.footer__download--appstore span {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.footer_share {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		padding-bottom: 10px;
		margin-left: 0;
	}

	.footer_agree {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.footer__container {
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 20px;
	}
}

@media (max-width: 767px) {
	.footer__section {
		position: relative;
		margin: 0px !important;
	}

	.footer_share {
		padding-bottom: 20px;
	}

	.footer_agree {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
}
