.campaign {
	background: url("../assets/images/land.png");
	min-height: 100vh;
	background-size: cover;
	position: relative;
}

.campaign::before {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(187, 34, 39, 0.4);
	content: "";
}
.campaign .box {
	margin: 30px 0px;
	background: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.board {
	padding: 20px;
}

.campaign input,
.campaign textarea,
.campaign select {
	height: 40px;
	width: 100%;
	outline: 0;
	border: 1px solid #aaa;
}

.campaign textarea {
	height: 80px !important;
}

.campaign .label {
	font-weight: 600;
	font-size: 0.8rem;
}

.campaign h5 {
	margin-bottom: 25px;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 10px;
}

.campaign .completed {
	text-align: center;
	padding: 50px 20px;
}

.campaign .completed .small {
	font-size: 1.2rem;
}

.campaign .logo {
	text-align: center;
	padding-top: 40px;
	position: relative;
	z-index: 99991;
}

.campaign .logo img {
	height: 70px;
}
