.home__carousel {
	text-align: center;
	margin-top: 30px;
}

.home__carousel img {
	border: 1px solid gray;
	border-radius: 5px;
}

.home__text {
	margin-top: 50px;
}

.home__text h4 {
	font-size: 2rem;
	font-weight: 800;
	text-align: center;
	margin-bottom: 30px;
}

.home__text .home__description {
	margin-top: 50px;
	font-size: 1.3rem;
	text-align: left;
	margin-bottom: 3rem;
}
