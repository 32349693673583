body {
	margin: 0;
	padding: 0;
	font-family: 'Segeo UI', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	color: grey;
	text-decoration: none;
}

a.active h5 {
	color: rgb(20, 18, 18);
	text-decoration: none;
	font-weight: 700;
	border-bottom: 3px dotted #bb2227;
}

a:hover {
	color: rgb(20, 18, 18);
	text-decoration: none;
}
