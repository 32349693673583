.sidebar {
	display: block;
	padding: 20px 50px;
	width: 400px;
	position: fixed;
	top: 0;
	z-index: -1;
	margin-bottom: 0;
	box-shadow: inset 0 0 0 100vmax rgba(187, 34, 39, 0.6);
	min-height: 90vh;
}

.img_wrapper {
	text-align: center;
	padding-top: 20px;
}

.img_wrapper p {
	margin-top: 10px;
	font-size: .9rem;
	font-style: normal;
	font-weight: 700;
	color: #fff;
}

@media (max-width: 991px) {
	.sidebar {
		margin-left: -420px;
	}
}
