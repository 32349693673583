.content {
	margin-left: 460px;
	position: relative;
	float: unset;
	clear: both;
	right: 2rem;
}

.app {
	margin-bottom: -10px;
}

@media (max-width: 991px) {
	.content {
		margin-left: 40px !important;
		margin-right: -20px !important;
	}
	.main {
		margin-right: 0;
	}
}

@media (max-width: 767px) {
	.wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.form input {
	width: 100%;
	display: block;
	margin-bottom: 20px;
	height: 50px;
	outline: 0;
	border: 1px solid rgba(0, 0, 0, 0.3);
	padding-left: 20px;
	border-radius: 3px;
}

.form button {
	background-color: #bb2227;
	color: #fff;
	border: 1px solid;
	padding: 8px 35px;
	border-radius: 4px;
	font-weight: 600;
}
