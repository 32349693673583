.navbar {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	position: relative;
}

.navbar__link {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar__link h5 {
	margin-right: 40px;
	font-size: 0.9rem;
}

.hamburger {
	display: none;
}

.menu {
	display: none;
}

@media (max-width: 992px) {
	.navbar {
		justify-content: center !important;
		margin-top: -20px !important;
	}
}
@media (min-width: 911px) {
	.mobile_logo {
		display: none;
	}
}

@media (max-width: 910px) {
	.navbar {
		justify-content: center !important;
	}

	.navbar__link {
		display: none;
	}

	.mobile_nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		margin-left: -20px;
		margin-right: -20px;
		padding: 10px 25px;
		box-shadow: inset 0 0 0 100vmax rgba(187, 34, 39, 0.6);
	}

	.hamburger {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		width: 25px;
		height: 20px;
	}

	.line {
		width: 100%;
		height: 5px;
		background-color: #fff;
		margin-top: 5px;
	}

	.menu {
		position: fixed;
		width: 50vw;
		height: 100vh;
		background-color: black;
		top: 0;
		z-index: 99;
		font-weight: bold;
		list-style: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: all 1s ease;
	}

	.menu__item {
		align-items: center;
		text-align: center;
		margin-top: 50px;
	}

	.cancel__btn {
		color: #fff;
		font-size: 10px;
		font-weight: bold;
		border: 1px solid #fff;
		border-radius: 100%;
		margin-top: 50px;
		padding: 5px 10px;
		text-align: left;
	}
}
