.carousel-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.carousel-control-prev-icon {
    fill: black;
    margin: 0px;
}
.carousel-control-next-icon {
    background-color: black;
    margin: 0px;
    fill: black
}

.carousel-indicators li {
    display: none;
}

@media (max-width: 767px) {
	
    .carousel-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}