.about {
	margin-top: 30px;
	padding-bottom: 100px;
}

.left__content {
	padding: 2rem;
	background: #fafafa;
	height: 750px;
	overflow-y: scroll;
	text-align: justify;
}

.left__content h5 {
	text-align: left;
	font-size: 1.8rem;
	font-weight: 700;
}

.left__content p {
	margin-top: 20px;
	text-align: left;
	font-size: 1.2rem;
	font-weight: 400;
	text-align: justify;
}

.left__content h4 {
	font-size: 1.8rem;
	font-weight: bold;
}

.left__content h6 {
	font-size: 25px;
	font-weight: bold;
}

@media (max-width: 767px) {
	.about {
		margin-bottom: 10px;
	}
}

.policy ul {
	font-size: 1.1rem;
}
