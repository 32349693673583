section {
    margin-top: 20px;
}

.recent {
	padding: 2rem;
	background: #fafafa;
	overflow-y: scroll;
	
}
.recent h5 span {
	background: #bb2227;
	height: 0.7rem;
	width: 0.7rem;
	border-radius: 50%;
	display: inline-block;
	margin-right: 1rem;
}
.recent h5 {
	font-weight: 800;
	font-size: 0.94rem;
	text-transform: capitalize !important;
}
.recent p {
	font-size: 01rem;
}
.recent h4 {
	font-weight: bold;
	font-size: 1.7rem;
	margin-bottom: 2rem;
	text-align: center;
}
@media (max-width: 767px) {
	.recent {
		margin-top: 3rem;
	}
}

@media (max-width: 767px) {
	
    section {
		margin-top: 0px;
        margin-bottom: 10px;
    }
}
