section {
    margin-top: 20px;
}

.recent {
	padding: 2rem;
	background: #fafafa;
	height: 600px;
    overflow-y: scroll;
	text-align: justify;
}
.recent p span {
	background: #bb2227;
	height: 0.7rem;
	width: 0.7rem;
	border-radius: 50%;
	display: inline-block;
	margin-right: 1rem;
}

/* h4 {
	font-weight: 800;
	font-size: 1.8rem;
	text-transform: capitalize !important;
	text-align: center;
} */
.recent p {
	font-size: 01rem;
}

.recent h4 {
	font-weight: 800;
	font-size: 1.8rem;
	text-transform: capitalize !important;
	text-align: center;
}
@media (max-width: 767px) {
	.recent {
		margin-top: 3rem;
	}
}

@media (max-width: 767px) {
	
    section {
		margin-top: 0px;
        margin-bottom: 10px;
    }
}
